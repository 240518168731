import React, { useState } from "react";
import { Image, Modal, Grid } from "semantic-ui-react";

import './Gallery.scss';

function getImages() {
  const maxImages = 21;
  const images = [];
  for (let i = 1; i < maxImages; i++) {
    const filePath = "/images/gallery/" + (i < 10 ? "0" + i : i);
    const image = filePath + ".jpg";
    images.push(image);
  }
  return images;
}

const images = getImages();

const ImageGalleryModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  function onModalClose() {
    setIsOpen(false);
    setModalImage("");
  }

  function onOpenModal(image) {
      setModalImage(image);
      setIsOpen(true);
  }

  const imagesToRender = images.map(image => (
    <Grid.Column>
      <Image src={image} onClick={() => onOpenModal(image)} />
    </Grid.Column>
  ));
  console.log(modalImage)

  return (
    <>
      <Grid stackable verticalAlign='middle' columns={3}>
        {imagesToRender}
      </Grid>
      <Modal open={isOpen} onClose={onModalClose}>
        <Modal.Content>
          <Image src={modalImage} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ImageGalleryModal;
