import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';

import './Footer.scss';

const Footer = () => (
    <Grid.Column textAlign='center'>
        <div className="about">RAE CLARK | &copy;2020 | Los Angeles, CA</div>
        <div className="social-icons">
            <a
                href="tel:7608098990"
            ><Icon fitted color="grey" name="phone"/></a>
            <a
                href="mailto:ashraeclark@gmail.com"
            ><Icon fitted color="grey" name="mail" /></a>
            <a
                href="//facebook.com/ashraeclark"
                target="_blank"
                rel="noopener noreferrer"
            ><Icon fitted color="grey" name="facebook square" /></a>
            <a
                href="//instagram.com/renrae_"
                target="_blank"
                rel="noopener noreferrer"
            ><Icon fitted color="grey" name="instagram" /></a>
        </div>
    </Grid.Column>
);

export default Footer;
