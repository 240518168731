import React from 'react';
import { Grid } from 'semantic-ui-react';

import './Video.scss';

const Videos = [
    {
        source: 'https://player.vimeo.com/video/125061808',
        title: 'LambRock',
        type: 'Short Documentary',
        location: 'Mt Woodson / Santee Boulders',
        year: '2015'
    },
    {
        source: 'https://player.vimeo.com/video/158213698',
        title: 'Beni',
        type: 'Gallery Installation',
        location: 'University of Calf., San Diego',
        year: '2015'
    },
    {
        source: 'https://www.youtube.com/embed/M2iYuQZpXuY',
        title: 'Stranded',
        type: 'Atmosphere piece',
        location: 'Joshua Tree',
        year: '2016'
    }
];

const VideoPlayer = (props) => {
    const { videoSrc } = props;
    const title = `iframe_${videoSrc}`;
    return (<iframe src={videoSrc} frameborder="0" title={title} webkitallowfullscreen mozallowfullscreen allowfullscreen />);
};

const VideoItem = (props) => {
    const { video } = props;
    const playerClass = 'video-player';
    return (
        <Grid.Row className="video-item">
            <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="video-container">
                    <div className={playerClass}>
                        <VideoPlayer videoSrc={video.source} />
                    </div>
                </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8} className="description">
                <h3>{video.title}</h3>
                <div>{video.type}</div>
                <div>{video.location}</div>
                <div>{video.year}</div>
            </Grid.Column>
        </Grid.Row>
    );
};

const Video = () => {
    const rendered = Videos.map(video => <VideoItem video={video} />);
    return (
        <Grid columns={2}>
            {rendered}
        </Grid>
    );
}

export default Video;