import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';

import Rae from '../images/rae.jpg';
import './Contact.scss'

const Contact = () => {
    return (
        <Grid columns={2}>
        <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={8} textAlign="left">
                <h3>EDUCATION</h3>
                <ul>
                    <li><span>University of California, San Diego</span></li>
                    <li><span>B. A. in Visual Arts, Multimedia / Film | 2012-2015</span></li>
                </ul>
                <h3>EXPERIENCE</h3>
                <ul>
                    <li><span>Angler Info, Creative Director</span> -- 2019, 2020</li>
                    <li><span>AFI, Assistant to Stage Manager (Panel Summits)</span> -- 2019</li>
                    <li><span>AFI, PreFest Volunteer (Print and Media)</span> -- 2019</li>
                    <li><span>Hasmat (short film), Assistant Director</span> -- 2019</li>
                    <li><span>Dream Sequence (experimental short film), Assistant Director</span> -- 2019</li>
                    <li><span>SIFF, Event and Guest Relations</span> -- 2019</li>
                    <li><span>Scopophilia, Script Supervisor and Production Assistant</span> -- 2019</li>
                    <li><span>Pilchuck Glass School, Director of Photography / Camera Operator / Editor</span> -- 2018, 2019</li>
                    <li><span>Dream Journal #3 (documentary), Second Camera Operator</span> -- 2018</li>
                    <li><span>KADYLUXE Production Assistant</span> -- 2016</li>
                    <li><span>MicroPACVideo Editor</span> -- 2016</li>
                    <li><span>FoLAR (Friends of the Los Angeles River org) Photographer</span> -- 2016</li>
                    <li><span>Triton Television Producer</span> -- 2013 to 2015</li>
                    <li><span>Spike &amp; Mike's Animation, Mellow Manor Productions</span> -- Fall 2014 Internship</li>
                    <li><span>KSDT Radio Station Sound Engineer</span> -- 2012 to 2013</li>
                    <li><span>Photographer at UCSD Guardian</span> -- Fall 2013</li>
                    <li><span>San Diego City College Media Lab &amp; Dark Room Assistant</span> -- 2011 to 2012</li>
                </ul>
                <h3>EXHIBITON</h3>
                <ul>
                    <li><span>Pilchuck Glass School (2018, 2019)</span> -- Staff exhibition. Seattle, Washington</li>
                    <li><span>Blabbermouth (2015)</span> -- Collaborative multidisciplinary performance. The Loft, San Diego</li>
                    <li><span>CANNED (2015)</span> -- Collaboration with Kyosan Ho and Dorothy Lee. Adam D. Kamil Gallery, San Diego</li>
                    <li><span>Untitled Body (2015)</span> -- Collaboration with Erina Alejo, Kyoson Ho, Dorothy Lee. Adam D.Kamil Gallery, San Diego</li>
                    <li><span>Filmatic Film Festival (2014)</span> -- Qualcomm Institute, San Diego</li>
                    <li><span>ArtPwr Up and Coming Film Festival (2013)</span> -- The Loft, San Diego</li>
                    <li><span>Clustrfcuk (2012)</span> -- Group show. Mandeville Annex Gallery, La Jolla</li>
                    <li><span>ICAM Death by Tech Happening (2012)</span> -- Digital art performance. Porter's Pub, La Jolla</li>
                </ul>
                <h3>RECOGNITION</h3>
                <ul>
                    <li><span>Best Picture and Best Cinematography award</span>, TTV 48hrFilmFestival (2015)</li>
                    <li><span>Arthur C. Clarke Center for Human Imagination Grant</span> (2015)</li>
                </ul>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
            <div className="contact">
                <p>
                    <a
                        href="tel:7608098990"
                    ><Icon fitted color="grey" name="phone"/></a>
                    <a
                        href="mailto:ashraeclark@gmail.com"
                    ><Icon fitted color="grey" name="mail" /></a>
                    <a
                        href="//facebook.com/ashraeclark"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><Icon fitted color="grey" name="facebook square" /></a>
                    <a
                        href="//instagram.com/renrae_"
                        target="_blank"
                        rel="noopener noreferrer"
                    ><Icon fitted color="grey" name="instagram" /></a>
                </p>
                </div>
                <img src={Rae} alt="Rae Clark" />
            </Grid.Column>
        </Grid.Row>
        </Grid>
    );
};

export default Contact;