import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { Routes } from '../App';
import './Nav.scss';

const NavItems = {
    PHOTOS: 'Photos',
    VIDEOS: 'Videos',
    CV: 'CV'
};

function determineActiveItem(path) {
    switch (path) {
        case Routes.VIDEOS:
            return NavItems.VIDEOS;
        case Routes.CV:
        case Routes.HOME:
            return NavItems.CV;
        case Routes.PHOTOS:
        default:
            return NavItems.PHOTOS;
    }
}

function determinePath(item) {
    switch (item) {
        case NavItems.VIDEOS:
            return Routes.VIDEOS;
        case NavItems.PHOTOS:
            return Routes.PHOTOS;
        case NavItems.CV:
        default:
            return Routes.CV;
    }
}

const Nav = () => {
    const location = useLocation();
    const history = useHistory();
    const startingItem = determineActiveItem(location.pathname);
    const [ activeItem, setActiveItem ] = useState(startingItem);
    useEffect(() => {
        const { pathname } = location;
        const currentItem = determineActiveItem(pathname);
        setActiveItem(currentItem);
    }, [ location ]);

    function onItemClick(item) {
        const path = determinePath(item);
        history.push(path);
    }

    return (
        <Menu secondary stackable borderless>
            <Menu.Item
                    name={NavItems.CV}
                    active={activeItem === NavItems.CV}
                    onClick={onItemClick.bind(null, NavItems.CV)}
            >{NavItems.CV}</Menu.Item>
            <Menu.Item
                    name={NavItems.PHOTOS}
                    active={activeItem === NavItems.PHOTOS}
                    onClick={onItemClick.bind(null, NavItems.PHOTOS)}
            >{NavItems.PHOTOS}</Menu.Item>
            <Menu.Item
                    name={NavItems.VIDEOS}
                    active={activeItem === NavItems.VIDEOS}
                    onClick={onItemClick.bind(null, NavItems.VIDEOS)}
            >{NavItems.VIDEOS}</Menu.Item>
        </Menu>
    );
};

export default Nav;
