import React from 'react';
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css'
import './App.scss';

import Footer from './components/Footer';
import Header from './components/Header';

import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Video from './pages/Video';

export const Routes = {
  HOME: '/',
  PHOTOS: '/photos',
  VIDEOS: '/videos',
  CV: '/cv'
};

function App() {
  return (
    <Router>
      <Grid container className="app">
        <Grid.Row className="header">
          <Header />
        </Grid.Row>
        <Grid.Row>
          <Switch>
            <Route exact path="/">
              <Contact />
            </Route>
            <Route path="/photos">
              <Gallery />
            </Route>
            <Route path="/videos">
              <Video />
            </Route>
            <Route path="/cv">
              <Contact />
            </Route>
          </Switch>
        </Grid.Row>
        <Grid.Row>
          <Footer />
        </Grid.Row>
      </Grid>
    </Router>
  );
}

export default App;
