import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import './Header.scss';

import Nav from './Nav';

const Header = () => (
    <Grid columns={2}>
        <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={4} textAlign="left">
                <Link className="home" to="/"><h3>RAE CLARK</h3></Link>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={12}  textAlign="right">
                <Nav />
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default Header;
